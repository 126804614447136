import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from 'react-admin';
import { login, logout, isLoggedIn } from './auth_func';

export default async (type, params) => {
  //console.log('auth type: ' + type + ' // ' + JSON.stringify(params));
  switch (type) {
    case AUTH_LOGIN: {
      const { username, password } = params;
      await login(username, password);
      return Promise.resolve();
    }
    case AUTH_LOGOUT:
      logout();
      return Promise.resolve();
    case AUTH_ERROR:
      const { status } = params;
      switch (status) {
        case 401:
          logout();
          return Promise.reject('unathorized');
        case 403:
          return Promise.reject('forbidden');
        default:
          return Promise.resolve();
      }
    case AUTH_CHECK:
      return isLoggedIn() ? Promise.resolve() : Promise.reject();
    case AUTH_GET_PERMISSIONS:
      const role = localStorage.getItem('role');
      //console.log('role: ' + role);
      return role ? Promise.resolve(role) : Promise.reject('error_no_role');
    default:
      return Promise.reject('error_unknown_method');
  }
};
