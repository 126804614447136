import React from 'react';
import {
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { permission, act } from '../permission';

const ItemsFilter = props => (
  <Filter {...props}>
    <TextInput label="поиск" source="q" alwaysOn />
  </Filter>
);

export const ItemsList = ({ permissions, ...props }) => (
  <List
    title="Ученики"
    filters={<ItemsFilter />}
    sort={{ field: 'title', order: 'ASC' }}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid rowClick={permission('classes', act.update) ? 'edit' : null}>
      <TextField source="title" label="наименование" />
      <TextField source="school.title" label="школа" />
      <TextField source="class.title" label="класс" />
      <TextField source="card" label="карта" />
      <NumberField source="balance" label="баланс" />
    </Datagrid>
  </List>
);

export default ItemsList;
