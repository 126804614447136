const api_uri = process.env.REACT_APP_API_URI || 'http://localhost:3003/agent';

function getToken() {
  let token = localStorage.getItem('token');

  if (token === 'undefined' || token === '') token = null;

  return token;
}

export async function logout() {
  /*
  todo: какой параметр передавать на logout?
  имя пользователя? проверять есть ли токен и
  имя пользователя, то не вызывать logout?
*/
  localStorage.removeItem('token');
  localStorage.removeItem('role');
  localStorage.removeItem('acl');

  // todo: не забыть убрать!!!
  /*
  const request = new Request(api_uri + '/v1/logout', {
    method: 'POST'
  });
  return fetch(request).then(response => {
    if (response.status < 200 || response.status >= 300)
      throw new Error('неудачный выход');
  });
*/
}

export async function login(username, password) {
  logout();
  const request = new Request(api_uri + '/v1/login', {
    method: 'POST',
    body: JSON.stringify({
      username,
      password
    }),
    credentials: 'include',
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  });
  return fetch(request)
    .then(response => {
      if (response.status < 200 || response.status >= 300)
        throw new Error('неудачный логин');

      const token = response.headers.get('token');

      if (!token) throw new Error('неудачный логин');

      localStorage.setItem('token', token);
      localStorage.setItem('role', response.headers.get('role'));
      localStorage.setItem('acl', response.headers.get('acl'));

      console.log('role:', response.headers.get('acl'));
    })
    .catch(error => {
      // todo: надо логировать на сервер
      console.log('login() error:', error);
    });
}

export function isLoggedIn() {
  // todo: делать онлайн проверку токена?
  if (!getToken()) return false;
  return true;
}

export function getResourcesForUser() {}
