import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Title } from 'react-admin';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import dataProvider from '../providers/data';
import { GET_ONE } from 'react-admin';

class CardContentContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplier: null
    };
  }

  componentDidMount() {
    this.fetchContact();
  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate: ' + JSON.stringify(prevProps));
    //this.fetchContact();
  }

  fetchContact = function() {
    dataProvider(GET_ONE, 'v1/profile/supplier', { id: 'contact' }).then(
      response => {
        console.log('fetchContact() response:', JSON.stringify(response));

        this.setState({
          supplier: response.data
        });
      }
    );
  };

  render() {
    const { supplier } = this.state;

    console.log('before render:', JSON.stringify(supplier));

    if (supplier) {
      return (
        <CardContent>
          <h1>{supplier.title}</h1>
          <p>
            {supplier.contact.data}
            <br />
            Email: {supplier.contact.email}
          </p>
        </CardContent>
      );
    } else {
      return null;
    }
  }
}

export default () => {
  return (
    <Card>
      <Title title="Информация" />
      <CardContentContact />
    </Card>
  );

  /*
  return (
    <Card className="">
      <CardHeader title="Профиль" />
      <List dense={true}>
        {profile.map(item => (
          <ListItem key={item.key} button component={Link} to="/v1/profile/10/edit">
            <ListItemText primary={item.title} secondary={item.key} />
            <ListItemSecondaryAction>
              <span className=""></span>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Card>
  );
*/
};
