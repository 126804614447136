// https://codesandbox.io/s/2393m2k5rj

import React from 'react';
import {
  Datagrid,
  DateField,
  ShowButton,
  EditButton,
  ReferenceManyField,
  RichTextField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  NumberField,
  Pagination,
  CardActions
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { permission, act, rs } from '../permission';

const ItemTitle = ({ record }) => {
  return (
    <span>
      покупка{' '}
      {record
        ? `${record['student.title']} // ${record['school.title']} // ${
            record['class.title']
          }`
        : ''}
    </span>
  );
};

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
};

const PurchaseShow = props => (
  <Show title={<ItemTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="товары">
        <ReferenceManyField
          addLabel={false}
          reference="v1/purchase_items"
          target="id"
          sort={{ field: 'food_item.title', order: 'DESC' }}
          pagination={<Pagination />}
          perPage={25}
        >
          <Datagrid>
            <TextField label="наименование" source="food_item.title" />
            <NumberField label="цена" source="price" />
            <NumberField label="количество" source="quantity" />
            <NumberField label="сумма" source="amount" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="общая информация">
        <TextField label="школьник" source="student.title" />
        <TextField label="школа" source="school.title" />
        <TextField label="класс" source="class.title" />
        <DateField
          source="created"
          label="дата/время"
          showTime
          locales="ru-RU"
        />
        <NumberField
          source="amount"
          label="сумма"
          locale="ru-RU"
          options={{ maximumFractionDigits: 2 }}
        />
        <TextField source="id" label="транзакция" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default PurchaseShow;
