import React from 'react';
import {
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  ReferenceInput,
  SelectInput,
  DateInput,
  FunctionField,
  UrlField
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { permission, act } from '../permission';

const messages = {
  type: {
    alfabank: 'Альфабанк эквайринг',
    terminal: 'Терминал'
  },
  status: {
    new: 'новый',
    in_progress: 'ожидание оплаты',
    completed: 'оплачено',
    fail: 'ошибка',
    deleted: 'удалён'
  }
};

const ItemsFilter = props => (
  <Filter {...props}>
    <DateInput label="период с:" source="date_from" alwaysOn locales="ru-RU" />
    <DateInput label="по:" source="date_till" alwaysOn />
  </Filter>
);

export const ItemsList = ({ permissions, ...props }) => (
  <List
    title="Платежи"
    filters={<ItemsFilter />}
    filterDefaultValues={{ date_from: new Date() }}
    sort={{ field: 'created', order: 'DESC' }}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid rowClick="show">
      <DateField source="created" label="дата/время" showTime locales="ru-RU" />
      <TextField
        source="amount"
        label="сумма"
        locale="ru-RU"
        options={{ maximumFractionDigits: 0 }}
      />
      <FunctionField
        label="статус"
        render={record => messages.status[record.status]}
      />
      <FunctionField
        label="тип операции"
        render={record => messages.type[record.type]}
      />
    </Datagrid>
  </List>
);

export default ItemsList;
