import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DisabledInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Datagrid,
  TextField,
  Toolbar,
  SaveButton,
  DateField,
  NumberField,
  LongTextInput,
  CardActions,
  ShowButton
} from 'react-admin';
import { permission, act } from '../permission';
import Button from '@material-ui/core/Button';

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton submitOnEnter={false} />
  </Toolbar>
);

// todo: сделать разным профили согласно роли

const daylimitValidation = (value, allValues, props) => {
  //console.log('allValues:', {value, allValues});

  if (value === null) {
    //return props.translate('message');
    return 'Лимит указывать обязательно, 0 - отключение лимита';
  }
  if (value < 0) {
    return 'Отключение лимита - укажите 0';
  }
  if (value > allValues.balance) {
    return 'Лимит не может быть больше баланса';
  }
  return undefined;
};

const handleClick = ({ basePath, staticContext, resource }) => {
  //const { push, record, showNotification } = this.props;

  console.log('arguments: ', { basePath, staticContext, resource, that: this });

  //  window.open(record['alfabank_formurl'], '_blank');

  //  push('/v1/payments');
};

const EditActions = ({ basePath, staticContext, resource }) => (
  <CardActions>
    <Button color="primary" href="/#/v1/profile/changepassword">
      Изменить пароль
    </Button>
  </CardActions>
);

const ProfileEdit = ({ permissions, staticContext, ...props }) => {
  return (
    <Edit
      title="редактирование профиля"
      id="me"
      resource="v1/profile"
      basePath="/v1/profile"
      redirect={false}
      actions={<EditActions />}
      undoable={false}
      {...props}
    >
      <SimpleForm toolbar={<EditToolbar />} redirect={'/'}>
        {!permission('profile', act.admin) && [
          <DisabledInput label="карта" source="card" />,
          <SelectInput
            label="статус карты"
            source="status"
            choices={[
              { id: 'active', name: 'Активна' },
              { id: 'disabled_by_user', name: 'Заблокирована' }
            ]}
          />,
          <NumberInput
            source="daylimit"
            label="Лимит в день"
            locale="ru-RU"
            validate={daylimitValidation}
          />,
          <DisabledInput label="Ф.И.О. ученика" source="title" />,
          <DisabledInput label="баланс" source="balance" />,
          <DisabledInput label="школа" source="school_title" />,
          <DisabledInput label="класс" source="class_title" />
        ]}
        {permission('profile', act.admin) && [
          <DisabledInput label="пользователь" source="title" />,
          <DisabledInput label="email" source="email" />,
          <DisabledInput label="роль" source="role" />
        ]}
      </SimpleForm>
    </Edit>
  );
};

export default ProfileEdit;
