import React from 'react';
import {
  Layout,
  MenuItemLink,
  UserMenu,
  AppBar,
  Admin,
  Resource
} from 'react-admin';
import { Route } from 'react-router-dom';

import authProvider from './providers/auth';
import dataProvider from './providers/data';
import i18nProvider from './providers/i18nProvider';

import SettingsIcon from '@material-ui/icons/Settings';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import ContactMail from '@material-ui/icons/ContactMail';

import { createMuiTheme } from '@material-ui/core/styles';

import { permission, act } from './permission';

import NotFound from './404';
import Dashboard from './dashboard';
import Login from './login';

import schools from './schools';
import classes from './classes';
import students from './students';
import teachers from './teachers';
import profile from './profile';
import info from './info';
import payments from './payments';
import alfabank from './alfabank';
import budget from './budget';
import topups from './topups';
import FoodRestrictions from './FoodRestrictions';
import purchases from './purchases';
import PurchaseItems from './PurchaseItems';
import print from './print';

/*
import russianMessages from 'ra-language-russian';

const messages = {
  ru: russianMessages
};

const i18nProvider = locale => messages[locale];
*/

//console.log('i18nProvider:', i18nProvider);

function getMainMenuItems() {
  let items = [
    <MenuItemLink
      to="/v1/profile"
      primaryText="Профиль"
      leftIcon={<EditIcon />}
    />
  ];

  if (!permission('profile', act.admin)) {
    items.push(
      <MenuItemLink
        to="/v1/info/contact"
        primaryText="Контактная информация"
        leftIcon={<ContactMail />}
      />
    );
  }

  items.push(
    <MenuItemLink to="#" primaryText="v0.12.1" leftIcon={<InfoIcon />} />
  );

  return items;
}

const AppMainMenu = props => (
  <UserMenu {...props}>{getMainMenuItems()}</UserMenu>
);

const MainAppBar = props => <AppBar {...props} userMenu={<AppMainMenu />} />;

const MainLayout = props => <Layout {...props} appBar={MainAppBar} />;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#13a569'
    },
    secondary: {
      main: '#284b99'
    },
    error: {
      main: '#c7254e'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    type: 'light'
  }
});

const App = () => (
  <Admin
    theme={theme}
    loginPage={Login}
    locale="ru"
    i18nProvider={i18nProvider}
    authProvider={authProvider}
    dataProvider={dataProvider}
    dashboard={Dashboard}
    appLayout={MainLayout}
    catchAll={NotFound}
    customRoutes={[
      <Route exact path="/v1/profile" component={profile.edit} />,
      <Route
        exact
        path="/v1/profile/changepassword"
        component={profile.ChangePassword}
      />,
      <Route exact path="/v1/info/contact" component={info.contact} />,
      <Route exact path="/v1/info/payment" component={info.payment} />,
      <Route
        exact
        path="/v1/alfabank/finish"
        component={alfabank.finish}
        noLayout
      />,
      <Route exact path="/v1/print/budget" component={print.budget} noLayout />
    ]}
  >
    {permissions => [
      permission('schools', act.read) ? (
        <Resource name="v1/schools" options={{ label: 'Школы' }} {...schools} />
      ) : null,

      permission('classes', act.read) ? (
        <Resource
          name="v1/classes"
          options={{ label: 'Классы' }}
          {...classes}
        />
      ) : null,

      permission('teachers', act.read) ? (
        <Resource
          name="v1/teachers"
          options={{ label: 'Учителя' }}
          {...teachers}
        />
      ) : null,

      permission('students', act.read) ? (
        <Resource
          name="v1/students"
          options={{ label: 'Ученики' }}
          {...students}
        />
      ) : null,

      permission('payments', act.read) ? (
        <Resource
          name="v1/payments"
          options={{ label: 'Платежи' }}
          {...payments}
        />
      ) : null,

      localStorage.getItem('role') === 'student' ? (
        <Resource
          name="v1/profile/restrictions"
          options={{ label: 'Ограничения на блюда' }}
          {...FoodRestrictions}
        />
      ) : null,

      permission('budget', act.read) ? (
        <Resource
          name="v1/budget"
          options={{ label: 'Отчет по бюджету' }}
          {...budget}
        />
      ) : null,

      permission('topups', act.read) ? (
        <Resource
          name="v1/topups"
          options={{ label: 'Отчет по пополнениям' }}
          {...topups}
        />
      ) : null,

      permission('purchases', act.read) ? (
        <Resource
          name="v1/purchases"
          options={{ label: 'Отчет по покупкам' }}
          {...purchases}
        />
      ) : null,

      permission('purchase_items', act.admin) ? (
        <Resource
          name="v1/purchase_items"
          options={{ label: 'Отчет по товарам' }}
          {...PurchaseItems}
        />
      ) : (
        <Resource name="v1/purchase_items" />
      ),

      <Resource name="v1/profile" />,

      <Resource name="v1/info" />,

      <Resource name="/v1/alfabank/finish" />,

      <Resource name="v1/food_menu" />
    ]}
  </Admin>
);

export default App;
