import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  DisabledInput,
  NumberInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';

const ItemTitle = ({ record }) => {
  return <span>Пополнение банковской картой</span>;
};

export const ItemCreate = ({ permissions, ...props }) => (
  <Create title={<ItemTitle />} {...props}>
    <SimpleForm redirect="show">
      <NumberInput label="сумма" source="amount" />
    </SimpleForm>
  </Create>
);

export default ItemCreate;
