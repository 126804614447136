import React from 'react';
import {
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceInput,
  SelectInput,
  DeleteButton,
  CardActions,
  CreateButton
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { permission, act } from '../permission';

const ItemsFilter = props => (
  <Filter {...props}>
    <TextInput label="поиск" source="q" alwaysOn />
  </Filter>
);

const ListActions = ({
  resource,
  filters,
  displayedFilters,
  filterValues,
  basePath,
  showFilter
}) => (
  <CardActions>
    <CreateButton label="Добавить" basePath={basePath} />
  </CardActions>
);

export const ItemsList = ({ permissions, ...props }) => (
  <List
    title="Ограничения на блюда"
    filters={<ItemsFilter />}
    sort={{ field: 'food_item_title', order: 'ASC' }}
    actions={<ListActions />}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid rowClick="null">
      <TextField source="food_item_title" label="наименование" />
      <TextField source="food_group_title" label="категория" />
      {permission('profile', act.update) && <DeleteButton />}
    </Datagrid>
  </List>
);

export default ItemsList;
