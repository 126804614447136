import React from 'react';
import {
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceInput,
  SelectInput,
  DateInput,
  FunctionField
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { permission, act } from '../permission';
import Aside from './aside';

const messages = {
  type: {
    alfabank: 'Альфабанк эквайринг',
    terminal: 'Терминал'
  }
};

const ItemsFilter = props => (
  <Filter {...props}>
    <DateInput label="период с:" source="date_from" alwaysOn />
    <DateInput label="по:" source="date_till" alwaysOn />

    {permission('schools', act.read) && (
      <ReferenceInput
        label="школа"
        source="school_id"
        reference="v1/schools"
        sort={{ field: 'title', order: 'ASC' }}
        alwaysOn
      >
        <SelectInput optionText="title" optionValue="id" />
      </ReferenceInput>
    )}
  </Filter>
);

export const ItemsList = ({ permissions, ...props }) => (
  <List
    title="отчёт по пополнению карт"
    filters={<ItemsFilter />}
    sort={{ field: 'id', order: 'ASC' }}
    bulkActionButtons={false}
    aside={<Aside />}
    {...props}
  >
    <Datagrid>
      <FunctionField
        label="тип пополнения"
        render={record => messages.type[record.id]}
      />
      <NumberField source="amount" label="сумма" />
      <NumberField source="count" label="количество" />
    </Datagrid>
  </List>
);

export default ItemsList;
