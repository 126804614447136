import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Title } from 'react-admin';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

let profile = [
  { key: 'Ф.И.О. ученика', title: 'Иванов Сергей Петрович' },
  { key: 'Карта', title: '1234567890' },
  { key: 'Школа', title: 'сош4' },
  { key: 'Класс', title: '7А' },
  { key: 'Баланс', title: 182 },
  { key: 'Роль', title: 'МО' },
  { key: 'Лимит в день', title: '100' },
  { key: 'Статус', title: 'активен' }
];

export default () => {
  return (
    <Card>
      <Title title="Школьное питание. Личный кабинет" />
      <CardContent>
        <h1>Информация:</h1>
        <p>
          <a href="/#/v1/info/payment">
            Правила оплаты и безопасность платежей, конфиденциальность
            информации
          </a>
          .
        </p>
        <p>
          Все нормативно-правовые акты по организации школьного питания и
          разработке меню можно просмотреть по{' '}
          <a
            href="http://bilim-oral.gov.kz/ru/shkolnoe-pitanie.html"
            target="blank"
          >
            ссылке
          </a>
          .
        </p>
        <p>
          <a
            href="https://drive.google.com/file/d/10fTHEWhp9pLSQaCOwEz2c_fwTn998o7M/view?usp=drivesdk"
            target="blank"
          >
            Меню
          </a>
          .
        </p>
      </CardContent>
    </Card>
  );
  /*
  return (
    <Card className="">
      <CardHeader title="Профиль" />
      <List dense={true}>
        {profile.map(item => (
          <ListItem key={item.key} button component={Link} to="/v1/profile/10/edit">
            <ListItemText primary={item.title} secondary={item.key} />
            <ListItemSecondaryAction>
              <span className=""></span>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Card>
  );
*/
};
