import React from 'react';
import {
  Datagrid,
  DateField,
  ShowButton,
  EditButton,
  ReferenceManyField,
  RichTextField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  NumberField,
  Pagination,
  CardActions,
  FunctionField,
  DateInput,
  SimpleShowLayout,
  translate
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { permission, act } from '../permission';
import moment from 'moment';
import PayButton from './paybutton';

const messages = {
  type: {
    alfabank: 'Альфабанк эквайринг',
    terminal: 'Терминал'
  },
  status: {
    new: 'новый',
    in_progress: 'ожидание оплаты',
    completed: 'оплачено',
    fail: 'ошибка',
    deleted: 'удалён'
  }
};

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right'
};

const ClientShowActions = ({ basePath, data, resource }) => (
  <CardActions style={cardActionStyle}>
    <PayButton record={data} />
  </CardActions>
);

const ClientShow = props => (
  <Show title="Платёж" actions={true ? <ClientShowActions /> : null} {...props}>
    <SimpleShowLayout>
      <DateField source="created" label="дата/время создания" locales="ru-RU" />
      <NumberField
        source="amount"
        label="сумма"
        locale="ru-RU"
        options={{ maximumFractionDigits: 0 }}
      />
      <FunctionField
        label="статус"
        render={record => messages.status[record.status]}
      />
      <FunctionField
        label="тип операции"
        render={record => messages.type[record.type]}
      />
      <FunctionField
        label="информация"
        render={record =>
          record.info && record.type === 'alfabank'
            ? `карта ${JSON.parse(record.info)['alfabank_pan']}`
            : ''
        }
      />
    </SimpleShowLayout>
  </Show>
);

export default ClientShow;
