import React, { Component } from 'react';
import { connect } from 'react-redux';
import dataProvider from '../providers/data';
import { UPDATE } from 'react-admin';

// https://github.com/parth-jbspl-24/biztech/blob/a439937e99d69aa74e96d57a036bd362f24cd5cd/examples/simple/src/customRouteNoLayout.js

// http://localhost:3000/#/v1/alfabank/finish?orderId=a562f1c7-bbcb-7834-9e45-30c001dddc1f&lang=ru

export default class CustomRouteNoLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateStatus: null
    };
  }

  componentDidMount() {
    this.updateStatus();
  }

  componentDidUpdate(prevProps) {
    console.warn('componentDidUpdate: ' + JSON.stringify(prevProps));
    //this.fetchContact();
  }

  closeAndGoBack = function() {
    let goBack = window.open('', 'lk.school07.kz');
    goBack.focus();
    window.close();
  };

  updateStatus = function() {
    console.warn('updateStatus(): ', JSON.stringify(this.props.location));

    let e;
    let params;
    try {
      params = this.props.location.search
        .match(/^\?(\S+)/)[1]
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=');
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {});
    } catch (e) {
      // todo: Sentry?
      params = {};
    }

    console.log('params: ', JSON.stringify(params));

    if (!params.orderId) {
      this.closeAndGoBack();
    } else {
      dataProvider(UPDATE, 'v1/payments', {
        id: 0,
        data: {
          orderid: params.orderId
        }
      })
        .then(() => {
          console.log('UPDATE ok');
          this.closeAndGoBack();
        })
        .catch(e => {
          // todo: Sentry?
          console.error('UPDATE fail', e);
          this.closeAndGoBack();
        });
    }
  };

  render() {
    return <h1>Можно закрывать это окно/вкладку</h1>;
  }
}

//export default connect()(CustomRouteNoLayout);

/*
export default () => {
  return (
    <CustomRouteNoLayout />
  );
};
*/
