import React from 'react';
import {
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceInput,
  SelectInput,
  DateInput,
  FunctionField,
  CheckboxGroupInput,
  DateField
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { permission, act } from '../permission';
import Aside from './aside';

const ItemsFilter = props => (
  <Filter {...props}>
    <DateInput label="период с:" source="date_from" alwaysOn />
    <DateInput label="по:" source="date_till" alwaysOn />

    {permission('schools', act.read) && (
      <ReferenceInput
        label="школа"
        source="school_id"
        reference="v1/schools"
        sort={{ field: 'title', order: 'ASC' }}
      >
        <SelectInput optionText="title" optionValue="id" />
      </ReferenceInput>
    )}

    {permission('class', act.read) && (
      <ReferenceInput
        label="класс"
        source="class_id"
        reference="v1/classes"
        sort={{ field: 'title', order: 'ASC' }}
      >
        <SelectInput optionText="title" optionValue="id" />
      </ReferenceInput>
    )}

    {localStorage.getItem('role') === 'admin' && (
      <CheckboxGroupInput
        label="роль"
        source="student_role"
        choices={[
          { id: 'student', name: 'ученик' },
          { id: 'student_mo', name: 'МО' },
          { id: 'service', name: 'служебная' }
        ]}
      />
    )}

    <TextInput label="школьник" source="q" />
  </Filter>
);

export const ItemsList = ({ permissions, ...props }) => (
  <List
    title="отчёт по покупкам"
    filters={<ItemsFilter />}
    sort={{ field: 'id', order: 'ASC' }}
    bulkActionButtons={false}
    aside={<Aside />}
    {...props}
  >
    <Datagrid rowClick="show">
      <DateField source="created" label="создание" showTime locales="ru-RU" />
      <TextField source="student.title" label="ученик" locale="ru-RU" />
      <TextField source="school.title" label="школа" locale="ru-RU" />
      <TextField source="class.title" label="класс" locale="ru-RU" />
      <NumberField
        source="amount"
        label="сумма"
        locale="ru-RU"
        options={{ maximumFractionDigits: 2 }}
      />
    </Datagrid>
  </List>
);

export default ItemsList;
