const act = {
  create: 'POST',
  read: 'GET',
  update: 'PUT',
  delete: 'DELETE',
  admin: 'ADMIN'
};

const permission = function(resource, action) {
  let acl = JSON.parse(localStorage.getItem('acl'));
  let result = false;
  for (let i = 0; i < acl.length; ++i) {
    if (
      acl[i].resource.join(',').indexOf(resource) >= 0 &&
      acl[i].action.join(',').indexOf(action) >= 0
    ) {
      result = true;
      break;
    }
  }
  console.log(`resource: ${resource} action: ${action} result: ${result}`);
  return result;
};

module.exports = {
  permission,
  act
};
