import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  DisabledInput,
  NumberInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';

const ItemTitle = ({ record }) => {
  return <span>новый товар</span>;
};

export const ItemCreate = ({ permissions, ...props }) => (
  <Create title={<ItemTitle />} {...props}>
    <SimpleForm>
      <TextInput label="наименование" source="title" />
      <NumberInput label="цена" source="default_price" />
      <NumberInput label="час приготовления" source="ready_hour" />
      <NumberInput label="срок годности" source="valid_hours" />
      <TextInput label="backoffice id" source="back_id" />
      {permissions === 'admin' && (
        <ReferenceInput label="агент" source="agent_id" reference="v1/agents">
          <SelectInput optionText="title" optionValue="id" />
        </ReferenceInput>
      )}
    </SimpleForm>
  </Create>
);

export default ItemCreate;
