import React, { Component } from 'react';
import dataProvider from '../providers/data';
import { GET_LIST, GET_ONE } from 'react-admin';

export default class CustomRouteNoLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
      error: null,
      dateFrom: '',
      dateTill: '',
      schoolTitle: 'все школы'
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const filters = {};
    for (let [key, value] of searchParams.entries()) {
      filters[key] = value;
    }

    delete filters['perPage'];
    delete filters['page'];

    // Получение названия школы по school_id
    const schoolId = filters.school_id;
    if (schoolId) {
      dataProvider(GET_ONE, 'v1/schools', { id: schoolId })
        .then(response => {
          this.setState({ schoolTitle: response.data.title });
        })
        .catch(error => {
          console.error('Ошибка при получении названия школы:', error);
        });
    }

    // Сохранение периода в состоянии
    this.setState({
      dateFrom: filters.date_from || '',
      dateTill: filters.date_till || ''
    });

    dataProvider(GET_LIST, 'v1/budget', {
      filter: filters,
      sort: { field: 'title', order: 'ASC' },
      pagination: { page: 1, perPage: 1000000 }
    })
      .then(response => {
        this.setState({ data: response.data, loading: false });
      })
      .catch(error => {
        this.setState({ error, loading: false });
      });
  };

  formatDate = dateString => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('ru-RU').format(date);
  };

  render() {
    const {
      data,
      loading,
      error,
      dateFrom,
      dateTill,
      schoolTitle
    } = this.state;

    if (loading) return <p>формирование отчета...</p>;
    if (error) return <p>Error: {error.message}</p>;

    // Форматирование дат в локальный формат
    const formattedDateFrom = this.formatDate(dateFrom);
    const formattedDateTill = dateTill
      ? this.formatDate(dateTill)
      : this.formatDate(new Date());

    return (
      <div style={styles.container}>
        <h1 style={styles.header}>Отчет по бюджету</h1>
        <p>
          <strong>Период:</strong> {formattedDateFrom} - {formattedDateTill}
        </p>
        <p>
          <strong>Школа:</strong> {schoolTitle}
        </p>

        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Ученики</th>
              <th style={styles.th}>МО</th>
              <th style={styles.th}>Сервис</th>
              <th style={styles.th}>Класс</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => (
              <tr key={item.id} style={styles.tr}>
                <td style={styles.td}>{item.students_count}</td>
                <td style={styles.td}>{item.students_mo_count}</td>
                <td style={styles.td}>{item.service_count}</td>
                <td style={styles.td}>{item.title}</td>
              </tr>
            ))}
            <tr className="total" style={styles.total}>
              <td style={styles.td}>
                {data.reduce((sum, item) => sum + item.students_count, 0)}
              </td>
              <td style={styles.td}>
                {data.reduce((sum, item) => sum + item.students_mo_count, 0)}
              </td>
              <td style={styles.td}>
                {data.reduce((sum, item) => sum + item.service_count, 0)}
              </td>
              <td style={styles.td}></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f4f4',
    margin: '20px',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
  },
  header: {
    textAlign: 'center',
    color: '#333'
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px'
  },
  th: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center',
    backgroundColor: '#4CAF50',
    color: 'white'
  },
  td: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center'
  },
  tr: {
    backgroundColor: '#f2f2f2'
  },
  total: {
    fontWeight: 'bold'
  }
};
