import ruMessages from './i18n/ru';

const messages = {
  ru: () => import('./i18n/ru.js').then(messages => messages.default)
};

export default locale => {
  // https://github.com/parth-jbspl-24/biztech/blob/a439937e99d69aa74e96d57a036bd362f24cd5cd/examples/simple/src/i18nProvider.js
  if (locale === 'должно быть "ru", но в такой конструкции не работает') {
    return messages[locale]();
  }

  console.log('i18n fallback');

  // Always fallback
  return ruMessages;
};
