import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Title } from 'react-admin';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

export default () => {
  return (
    <Card>
      <Title title="Информация" />
      <CardContent>
        <h1>
          Правила оплаты и безопасность платежей, конфиденциальность информации
        </h1>

        <p>
          Наш сайт подключен к интернет-эквайрингу, и Вы можете оплатить Услугу
          банковской картой Visa или Mastercard. После подтверждения выбранного
          Товара либо услуги откроется защищенное окно с платежной страницей АО
          Банк ЦентрКредит, где Вам необходимо ввести данные Вашей банковской
          карты. Для дополнительной аутентификации держателя карты используется
          протокол 3-D Secure. Если Ваш Банк-эмитент поддерживает данную
          технологию, Вы будете перенаправлены на его сервер для прохождения
          дополнительной идентификации. Информацию о правилах и методах
          дополнительной идентификации уточняйте в Банке, выдавшем Вам
          банковскую карту.
        </p>
        <p>
           Услуга онлайн-оплаты осуществляется в соответствии с правилами
          Международных платежных систем Visa и MasterCard на принципах
          соблюдения конфиденциальности и безопасности совершения платежа, для
          этого используются самые актуальные методы проверки, шифрования и
          передачи данных по закрытым каналам связи. Ввод данных банковской
          карты осуществляется в защищенном окне на платежной странице АО Банк
          ЦентрКредит.
        </p>
        <p>
           В поля на платежной странице требуется ввести номер карты, имя
          владельца карты, срок действия карты, трёхзначный код безопасности
          (CVV2 для VISA или CVC2 для MasterCard). Все необходимые данные
          отображены на поверхности банковской карты.
        </p>
        <p>
          CVV2/ CVC2 — это трёхзначный код безопасности, находящийся на
          оборотной стороне карты.
        </p>
        <p>
          Далее в том же окне откроется страница Вашего банка-эмитента для ввода
          3-D Secure кода. В случае, если у вас не настроен статичный 3-D
          Secure, он будет отправлен на ваш номер телефона посредством SMS. Если
          3-D Secure код к Вам не пришел, то следует обратится в ваш
          банк-эмитент.
        </p>
        <p>
           3-D Secure — это самая современная технология обеспечения
          безопасности платежей по картам в сети интернет. Позволяет однозначно
          идентифицировать подлинность держателя карты, осуществляющего
          операцию, и максимально снизить риск мошеннических операций по карте.
        </p>

        <h1>Гарантии безопасности</h1>

        <p>
          АО Банк ЦентрКредит защищает и обрабатывает данные Вашей банковской
          карты по стандарту безопасности PCI DSS 3.0. Передача информации в
          платежный шлюз происходит с применением технологии шифрования SSL.
          Дальнейшая передача информации происходит по закрытым банковским
          сетям, имеющим наивысший уровень надежности. АО Банк ЦентрКредит не
          передает данные Вашей карты нам и иным третьим лицам. Для
          дополнительной аутентификации держателя карты используется протокол
          3-D Secure.
        </p>
      </CardContent>
    </Card>
  );
};
