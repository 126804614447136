import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  DisabledInput,
  NumberInput,
  ReferenceInput,
  SelectInput
} from 'react-admin';

const ItemTitle = ({ record }) => {
  return <span>Добавление блюда в список ограничений</span>;
};

export const ItemCreate = ({ permissions, ...props }) => (
  <Create title={<ItemTitle />} {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput
        label="выберите блюдо"
        source="id"
        reference="v1/food_menu"
        sort={{ field: 'food_item_title', order: 'ASC' }}
        perPage={200}
      >
        <SelectInput optionText="food_item_title" optionValue="id" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export default ItemCreate;
