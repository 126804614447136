import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import dataProvider from '../providers/data';
import { GET_LIST } from 'react-admin';

class AsideBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '-',
      count: '-'
    };
  }

  componentDidMount() {
    const { filterValues } = { ...this.props };
    //console.log('filterValues:' + JSON.stringify(filterValues, null, 2));
    this.fetchTotals(filterValues);
  }

  componentDidUpdate(prevProps) {
    const { filterValues } = { ...this.props };

    //console.log('this.props.id:', JSON.stringify(prevProps.id));

    const prevFilterValues = prevProps.filterValues;
    if (JSON.stringify(prevFilterValues) !== JSON.stringify(filterValues)) {
      this.fetchTotals(filterValues);
    }
    /*
    console.log('componentDidUpdate()');
    this.fetchTotals(filterValues);
    */
  }

  fetchTotals = filterValues => {
    let xfilterValues = JSON.parse(JSON.stringify(filterValues));
    xfilterValues.xtotals = true;

    console.log('xfilterValues: ' + JSON.stringify(xfilterValues));

    dataProvider(GET_LIST, 'v1/topups', {
      filter: xfilterValues,
      sort: {
        field: 'id',
        order: 'DESC'
      },
      pagination: {
        page: 1,
        perPage: 10
      }
    }).then(response => {
      console.log(
        'X response: ' +
          response.data.length +
          ' // ' +
          JSON.stringify(response.data)
      );

      if (response.data.length > 0) {
        this.setState({
          amount: response.data[0].amount,
          count: response.data[0].count
        });
      }
    });
  };

  render() {
    const { amount, count } = this.state;

    return (
      <div style={{ width: 200, margin: '1em' }}>
        <Typography variant="title">Итоги</Typography>
        <Typography variant="body1">
          <br />
          сумма: <b>{amount || 0}</b> kz;
          <br />
          количество: <b>{count || 0}</b> платежей;
          <br />
        </Typography>
      </div>
    );
  }
}

export default AsideBudget;
