import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DisabledInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Datagrid,
  TextField,
  Toolbar,
  SaveButton,
  DateField,
  NumberField,
  LongTextInput,
  CardActions,
  ShowButton
} from 'react-admin';
import { permission, act } from '../permission';
import Button from '@material-ui/core/Button';

const ChangePasswordEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton submitOnEnter={false} />
  </Toolbar>
);

const ChangePasswordEditActions = ({ basePath, staticContext, resource }) => (
  <CardActions>
    <Button color="primary" href="/#/v1/profile">
      Профиль
    </Button>
  </CardActions>
);

const ChangePasswordEditValidate = values => {
  const errors = {};
  if (!values.old_password) {
    errors.old_password = ['Пожалуйста введите пароли'];
  }
  if (!values.new_password) {
    errors.new_password = ['Пожалуйста введите пароль'];
  }
  if (!values.new_password_repeat) {
    errors.new_password_repeat = ['Пожалуйста повторите пароль'];
  }
  if (values.new_password != values.new_password_repeat) {
    errors.new_password_repeat = ['Новые пароли должны совпадать'];
  } else if (String(values.new_password).length < 4) {
    errors.new_password = ['Новый пароль должен быть не меньше 4 символов'];
  }

  return errors;
};

const ChangePasswordEdit = ({ permissions, staticContext, ...props }) => {
  return (
    <Edit
      title="Изменение пароля"
      id="me"
      resource="v1/profile"
      basePath="/v1/profile/changepassword"
      redirect={false}
      actions={<ChangePasswordEditActions />}
      undoable={false}
      {...props}
    >
      <SimpleForm
        toolbar={<ChangePasswordEditToolbar />}
        redirect={'/'}
        validate={ChangePasswordEditValidate}
      >
        {!permission('profile', act.admin) && [
          <TextInput
            source="old_password"
            label="Текущий пароль"
            type="password"
          />,
          <TextInput
            source="new_password"
            label="Новый пароль"
            type="password"
          />,
          <TextInput
            source="new_password_repeat"
            label="Новый пароль"
            helperText="пожалуйста повторите для контроля ввода"
            type="password"
          />
        ]}
        {permission('profile', act.admin) && [
          <DisabledInput label="пользователь" source="title" />,
          <DisabledInput label="email" source="email" />,
          <DisabledInput label="роль" source="role" />
        ]}
      </SimpleForm>
    </Edit>
  );
};

export default ChangePasswordEdit;
