import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { showNotification as showNotificationAction } from 'react-admin';
import { push as pushAction } from 'react-router-redux';

class PayButton extends Component {
  handleClick = () => {
    const { push, record, showNotification } = this.props;

    window.open(record['alfabank_formurl'], '_blank');

    push('/v1/payments');
  };

  render() {
    const { record } = this.props;

    if (!record) return null;

    if (localStorage.getItem('role').indexOf('student') === -1) return null;

    if (record.type != 'alfabank') return null;

    if ('new,in_progress'.indexOf(record.status) === -1) return null;

    // todo: тут бы sentry?
    if (!record.alfabank_formurl) return null;

    return (
      <Button onClick={this.handleClick} color="primary">
        Оплатить{' '}
      </Button>
    );
  }
}

PayButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func
};

export default connect(
  null,
  {
    showNotification: showNotificationAction,
    push: pushAction
  }
)(PayButton);
