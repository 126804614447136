import ruMessages from 'ra-language-russian';

export default {
  ...ruMessages,
  resources: {
    payments: {
      fields: {
        type: {
          alfabank: 'Альфабанк эквайринг',
          terminal: 'Терминал'
        },
        status: {
          new: 'новый',
          in_progress: 'ожидается оплата',
          completed: 'оплачен',
          fail: 'ошибка'
        }
      }
    }
  }
};
