import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  DisabledInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  FormTab,
  ReferenceManyField,
  Datagrid,
  TextField
} from 'react-admin';
import { permission, acRead } from '../permission';

const ItemTitle = ({ record }) => {
  return <span>редактирование {record ? `${record.title}` : ''}</span>;
};

export const ItemEdit = ({ permissions, ...props }) => (
  <Edit title={<ItemTitle />} {...props}>
    <TabbedForm>
      <FormTab label="Client">
        {permission('agents', acRead) && (
          <DisabledInput label="id" source="id" />
        )}
        <TextInput label="наименование" source="title" />
        <NumberInput label="цена" source="default_price" />
        <NumberInput label="час приготовления" source="ready_hour" />
        <NumberInput label="срок годности" source="valid_hours" />
        <TextInput label="backoffice id" source="back_id" />
        {permission('agents', acRead) && (
          <ReferenceInput label="агент" source="agent_id" reference="v1/agents">
            <SelectInput optionText="title" optionValue="id" />
          </ReferenceInput>
        )}
      </FormTab>
      <FormTab label="Addresses" path="v1/kioxes">
        <ReferenceManyField label="" reference="v1/kioxes" target="id">
          <Datagrid>
            <TextField source="title" label="Title" />
            <TextField source="city" />
            <TextField source="zipCode" />
            <TextField source="country" />
            <TextField source="isPrimary" />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export default ItemEdit;
