import React from 'react';
import { Login, LoginForm } from 'ra-ui-materialui';

const ProjectLogin = props => (
  <Login
    loginForm={<LoginForm />}
    backgroundImage="https://source.unsplash.com/1600x900/daily?food"
    {...props}
  />
);

export default ProjectLogin;
